// ローディング判定
jQuery(window).on("load", function() {
  jQuery("body").attr("data-loading", "true");
});

jQuery(function() {
  // スクロール判定
  jQuery(window).on("scroll", function() {
    let scrollTop = jQuery(this).scrollTop();
    let windowHeight = jQuery(this).height();
    let documentHeight = jQuery(document).height();

    if (400 < scrollTop) {
      jQuery("body").attr("data-scroll", "true");
    } else {
      jQuery("body").attr("data-scroll", "false");
    }

    if (documentHeight - (windowHeight + scrollTop) <= 0) {
      jQuery("body").attr("data-scroll-bottom", "true");
    } else {
      jQuery("body").attr("data-scroll-bottom", "false");
    }
  });

  /* ドロワー */
  jQuery(".js-drawer").on("click", function(e) {
    e.preventDefault();
    let targetClass = jQuery(this).attr("data-target");
    let ariaControls = jQuery(this).attr("aria-controls");
    jQuery("." + targetClass).toggleClass("is-checked");
    jQuery(body).toggleClass("is-checked");

    if (jQuery("#" + ariaControls).attr("aria-hidden") === "true") {
      jQuery("#" + ariaControls).attr("aria-hidden", "false");
    } else {
      jQuery("#" + ariaControls).attr("aria-hidden", "ture");
    }

    if (jQuery(this).attr("aria-expanded") === "true") {
      jQuery(this).attr("aria-expanded", "false");
    } else {
      jQuery(this).attr("aria-expanded", "ture");
    }
    return false;
  });

  jQuery(".sp-btn").on("click", function() {
    jQuery(this)
      .next()
      .slideToggle();
  });
});

var urlHash = location.hash;
if (urlHash) {
  jQuery("body,html")
    .stop()
    .scrollTop(0);

  setTimeout(function() {
    var target = jQuery(urlHash);
    var position = target.offset().top - 80;
    //ランダムっぽいのでどちらにも対応
    jQuery("body,html")
      .stop()
      .animate({ scrollTop: position }, 300);
  }, 100);
}

jQuery('a[href^="#"]').click(function() {
  let id = jQuery(this).attr("href");
  let speed = 300;
  let target = jQuery("#" == id ? "html" : id);
  let position = jQuery(target).offset().top - 80;
  if ("fixed" !== jQuery("header").css("position")) {
    position = jQuery(target).offset().top;
  }

  jQuery("html, body").animate(
    {
      scrollTop: position
    },
    speed
  );
  return false;
});
